import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { Row, Col, Skeleton, Tag, Pagination, Divider, Menu, Drawer, ConfigProvider, BackTop, Image } from 'ant-design-vue'
// import 'ant-design-vue/dist/antd.css'
import 'animate.css/animate.min.css'

import VMdPreview from '@kangc/v-md-editor/lib/preview'
import '@kangc/v-md-editor/lib/style/preview.css'
import vuepressTheme from '@kangc/v-md-editor/lib/theme/vuepress.js'
import '@kangc/v-md-editor/lib/theme/style/vuepress.css'
import Prism from 'prismjs'

import createCopyCodePlugin from '@kangc/v-md-editor/lib/plugins/copy-code/index'
import '@kangc/v-md-editor/lib/plugins/copy-code/copy-code.css'
import createKatexPlugin from '@kangc/v-md-editor/lib/plugins/katex/cdn'

VMdPreview.use(vuepressTheme, {
  // Hljs: hljs, github主题使用
  Prism // vuepress主题使用高亮包
}).use(createCopyCodePlugin()).use(createKatexPlugin())

const components = [Row, Col, Skeleton, Tag, Pagination, Divider, Menu, Drawer, ConfigProvider, BackTop, Image]
const app = createApp(App)
components.forEach(plugin => {
  app.use(plugin)
})
app.use(store).use(router).use(VMdPreview).mount('#app')
