<template>
  <div>
<!--    <a-image-preview-group>-->
<!--      <a-image :src="item" v-for="(item, index) in imageList" :key="index"/>-->
<!--    </a-image-preview-group>-->
    <div style="display: none">
      <ul id="images">
        <li v-for="(item, index) in imageList" :key="index"><img :src="item" class="img-viewer"></li>
      </ul>
    </div>
  </div>
  <div class="post">
    <a-row type="flex" justify="center">
      <a-col :xs="23" :sm="23" :md="16" :lg="12" style="padding: 5px">
        <div v-if="waiting">
          <a-skeleton active v-for="item in 10" :key="item"/>
        </div>
        <div style="width: 100%" v-if="!waiting">
          <div class="title">{{ post.title }}</div>
          <a-divider/>
          <div style="width: 100%">
            <p class="introduction"><span style="font-weight: bold">简介：</span>{{ post.introduction }}</p>
          </div>
          <a-divider/>
          <v-md-preview :text="post.content" @imageClick="handImgClick"></v-md-preview>
          <div class="tip">
            <a-divider>THE END</a-divider>
            <div>
              文章标签：
              <a-tag :color="item.color" v-for="(item, index) in post.blogTagCodeTagList" :key="index">{{ item.name }}</a-tag>
            </div>
            <div>原文连接：<a :href="postUrl" target="_blank">{{ postUrl }}</a></div>
            <div style="font-weight: bold">注意事项：文章最后编辑于{{ post.updateDate }}，除非注明，否则均为个人博客原创文章，转载请注明出处。</div>
          </div>
        </div>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import { reactive, ref, nextTick } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { blogApi } from '@/util/api'

export default {
  name: 'Post',
  setup () {
    const route = useRoute()
    const router = useRouter()
    const id = route.params.id
    const post = reactive({})
    const waiting = ref(false)
    const postUrl = ref()
    const imageList = ref([])
    let gallery
    const search = async () => {
      if (id) {
        waiting.value = true
        const res = await blogApi.getById(id)
        waiting.value = false
        if (res) {
          Object.assign(post, res.data)
          window.document.title = '秋瑜-' + post.title
          postUrl.value = window.location.href
        } else {
          router.push('/')
        }
      } else {
        router.push('/')
      }
    }
    search()
    const handImgClick = (images, imagePreviewInitIndex) => {
      if (imageList.value.length === 0) {
        imageList.value = images
      }
      nextTick(() => {
        if (!gallery) {
          // eslint-disable-next-line no-undef
          gallery = new Viewer(document.getElementById('images'))
        }
        const current = images[imagePreviewInitIndex]
        const imgDom = window.document.getElementsByClassName('img-viewer')
        console.log(imgDom)
        for (let i = 0; i < imgDom.length; i++) {
          if (imgDom[i].src === current) {
            imgDom[i].click()
            break
          }
        }
      })
    }
    return {
      post,
      waiting,
      postUrl,
      handImgClick,
      imageList
    }
  }
}
</script>

<style scoped>
p {
  margin-bottom: 0;
}

.title {
  width: 100%;
  text-align: center;
  font-size: 21px;
  /*line-height: 50px;*/
  font-weight: 600;
  /*white-space: nowrap; !*文本不换行*!*/
  /*overflow: hidden;*/
  /*text-overflow: ellipsis; !*显示省略符号来代表被修剪的文本。*!*/
}

.tip {
  width: 100%;
  font-size: 10px;
  /*justify-content: space-between;*/
  /*display: flex*/
}

.introduction {
  color: #6f6f6f;
  line-height: 30px;
  font-size: 15px;
  letter-spacing: 1px;
  /*text-indent:2em;*/
}
</style>
