<template>
  <div class="home">
    <a-row type="flex" justify="center">
      <a-col :xs="24" :sm="24" :md="16" :lg="12" style="padding: 5px">
        <div v-if="waiting">
          <a-skeleton active v-for="item in 10" :key="item"/>
        </div>
        <wj-card v-for="(item, index) in list" :key="index" class="animate__animated" :class="index%2 === 0 ? 'animate__lightSpeedInLeft' : 'animate__lightSpeedInRight'">
          <router-link :to="{path:`/post/${item.id}`}">
            <p class="title"><span style="color: firebrick" v-if="item.isTop">[TOP]</span>{{item.title}}</p>
          </router-link>
          <p class="introduction">{{item.introduction}}</p>
          <div class="tip">
            <div>
              <a-tag :color="tag.color" v-for="(tag, tagIndex) in item.blogTagCodeTagList" :key="tagIndex">{{tag.name}}</a-tag>
            </div>
            <div>{{item.updateDate}}</div>
          </div>
        </wj-card>
<!--        <div v-for="(item, index) in list" :key="index" class="animate__animated" :class="index%2 === 0 ? 'animate__lightSpeedInLeft' : 'animate__lightSpeedInRight'">-->
<!--          -->
<!--          <a-divider />-->
<!--        </div>-->
        <div style="text-align: center">
          <a-pagination size="small"
                        :total="total"
                        v-model:current="current"
                        v-model:pageSize="size"
                        show-size-changer
                        show-quick-jumper
                        @change="handlePageChange"
                        @showSizeChange="handlePageChange"
          />
        </div>
      </a-col>
<!--      <a-col :xs="24" :sm="24" :md="4" :lg="4" style="padding: 5px">-->
<!--        <div style="border: 1px red solid">右边</div>-->
<!--      </a-col>-->
    </a-row>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue'
import { blogApi } from '@/util/api'
import WjCard from '@/components/WjCard'
export default {
  name: 'Home',
  components: { WjCard },
  setup () {
    window.document.title = '秋瑜'
    const list = ref([])
    const current = ref(1)
    const size = ref(10)
    const total = ref(0)
    const waiting = ref(false)
    const handleSearch = async () => {
      waiting.value = true
      const res = await blogApi.page({ current: current.value, size: size.value })
      waiting.value = false
      if (res) {
        list.value = res.data.records
        total.value = res.data.total
      }
    }
    const handlePageChange = () => {
      handleSearch()
    }
    onMounted(() => {
      handleSearch()
    })
    return {
      list,
      size,
      current,
      total,
      waiting,
      handlePageChange
    }
  }
}
</script>

<style scoped>
  p {
    margin-bottom: 0;
  }
  .title{
    color: black;
    font-size: 18px;
    /*line-height: 30px;*/
    font-weight: 600;
    white-space: nowrap; /*文本不换行*/
    overflow: hidden;
    text-overflow: ellipsis; /*显示省略符号来代表被修剪的文本。*/
  }
  .title:hover{
    text-decoration: underline;
  }
  .introduction{
    color: #6f6f6f;
    line-height: 30px;
    font-size: 15px;
    letter-spacing: 1px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }
  .tip{
    color: #6f6f6f;
    /*font-size: 12px;*/
    width: 100%;
    justify-content: space-between;
    display: flex
  }
</style>
