import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'Layout',
    component: () => import(/* webpackChunkName: "about" */ '../components/layout/index.vue'),
    children: [
      {
        path: '/',
        name: 'Home',
        component: () => import(/* webpackChunkName: "about" */ '../views/Home.vue')
      },
      {
        path: '/post/:id',
        name: 'Post',
        component: () => import(/* webpackChunkName: "about" */ '../views/Post.vue')
      }
    ]
  },
  {
    path: '/:pathMatch(.*)',
    name: 'all',
    redirect: '/'
  }

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
