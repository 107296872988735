<template>
  <div class="footer">
    WJ BLOG WEB ©2021 Created by WJ
  </div>
</template>

<style scoped>
  .footer{
    padding: 30px 0;
    width: 100%;
    text-align: center;
  }
</style>
