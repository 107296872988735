<template>
  <div class="header">
    <a-row type="flex" justify="space-between" align="middle">
      <a-col :xs="8" :sm="6" :md="6" :lg="4" style="text-align: right;" class="col">
        <div style="height: 60px;line-height: 60px">
          <img src="@/assets/image/logo.png" style="height: 50px;cursor: pointer" @click="handleLogoClick">
        </div>
      </a-col>
      <a-col :xs="0" :sm="0" :md="6" class="col">
        <a-menu mode="horizontal" style="height: 60px;font-size: 16px">
          <a-menu-item v-for="item in menuList" :key="item.key" @click="handleMenuClick(item.path)">{{item.title}}</a-menu-item>
        </a-menu>
      </a-col>
      <a-col :xs="4" :sm="4" :md="0" class="col">
        <UnorderedListOutlined @click="mobileMenuVisible = !mobileMenuVisible"/>
        <a-drawer
          placement="right"
          :body-style="{ paddingLeft: '0', paddingRight: '0' }"
          v-model:visible="mobileMenuVisible"
        >
          <template #title>
            <img src="@/assets/image/logo.png" style="height: 50px">
          </template>
          <a-menu mode="vertical" style="width: 100%">
            <a-menu-item v-for="item in menuList" :key="item.key" @click="handleMenuClick(item.path)">{{item.title}}</a-menu-item>
          </a-menu>
        </a-drawer>
      </a-col>
    </a-row>
  </div>
</template>
<script>
import { UnorderedListOutlined } from '@ant-design/icons-vue'
import { useRouter } from 'vue-router'
import { ref } from 'vue'
export default {
  name: 'WjLayoutHeader',
  components: { UnorderedListOutlined },
  setup () {
    const mobileMenuVisible = ref(false)
    const menuList = ref([
      { key: 'home', title: '首页', path: '/' },
      { key: 'about', title: '关于', path: '/' }
    ])
    const router = useRouter()
    const handleMenuClick = path => {
      mobileMenuVisible.value = false
      router.push(path)
    }
    const handleLogoClick = () => {
      router.push('/')
    }
    return {
      mobileMenuVisible,
      menuList,
      handleMenuClick,
      handleLogoClick
    }
  }
}
</script>
<style scoped lang="less">
  .header{
    width: 100%;
    box-shadow: 0 0 5px 5px #DDDDDD;
    background-color: white;
    .col{
      vertical-align: middle;
    }
  }
  .ant-menu-horizontal{
    line-height: 60px;
  }
</style>
