<template>
  <div class="layout-content-div">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'WjCard'
}
</script>

<style scoped>
  .layout-content-div {
    margin: 0px 0px 10px 0px;
    padding: 10px;
    width: 100%;
    background-color: #ffffff;
    box-shadow: 0 0 2px 2px #DDDDDD;
    border-radius: 5px;
  }
  /*.layout-content-div:hover {*/
  /*  box-shadow: 0 0 5px 5px #DDDDDD;*/
  /*}*/
</style>
