import axios from 'axios'
import { message, Modal } from 'ant-design-vue'

const http = axios.create({
  timeout: 1000 * 10,
  baseURL: process.env.VUE_APP_BASE_URL,
  withCredentials: false,
  timeoutErrorMessage: '连接超时'
})

export async function requestAsync (options) {
  const defaultOptions = {
    method: 'GET',
    errorPrompt: true,
    successPrompt: false,
    loading: false
  }
  const currentOptions = Object.assign(defaultOptions, options)
  try {
    if (options.loading === true) {
      message.loading('loading', 0)
    }
    const response = await http.request(options)
    if (options.loading === true) {
      message.destroy()
    }
    const res = response.data
    if (res.code === 200) {
      if (currentOptions.successPrompt) {
        message.success(res.msg)
      }
      return res
    } else {
      if (currentOptions.errorPrompt === true) {
        codeOtherHandler(res.msg)
      }
    }
    return false
  } catch (e) {
    message.destroy()
    if (currentOptions.errorPrompt === true) {
      errorHandler(e.message)
    }
    return false
  }
}

const codeOtherHandler = msg => {
  Modal.warn({
    width: 600,
    centered: true,
    title: '业务提示',
    content: msg
  })
}

const errorHandler = msg => {
  Modal.error({
    width: 600,
    centered: true,
    title: '错误提示',
    content: msg
  })
}
