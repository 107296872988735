<template>
  <wj-layout-header/>
  <wj-layout-content style="margin-top: 10px"/>
  <wj-layout-footer/>
  <a-back-top />
</template>

<script>
import WjLayoutHeader from '@/components/layout/views/WjLayoutHeader.vue'
import WjLayoutFooter from '@/components/layout/views/WjLayoutFooter.vue'
import WjLayoutContent from '@/components/layout/views/WjLayoutContent.vue'
export default {
  name: 'Layout',
  components: { WjLayoutHeader, WjLayoutContent, WjLayoutFooter }
}
</script>
